<template>
   <div>
     <button class="wxAuth" @click="startShard">点击分享（朋友圈）</button>
      <button class="wxAuth" @click="startShardFriend">点击分享（好友）</button>
   </div>
</template>

<script>

/**
 * 分享 交互
 *
 * @param sceneType     分享场景类型     0：分享到微信会话  1：分享到朋友圈
 * @param shareType     分享类型        1:文字 2:图片 3:网址
 * @param title         分享标题
 * @param description   分享详细说明
 * @param picUrl        分享页面小图标
 * @param picType       分享图片类型     0:h5地址  1: base64 字符串
 * @param shareLink     分享邀请链接地址
 * @return
public void shareToWX(int sceneType,int shareType,String title, String description, String picUrl,int picType,  String shareLink)
 */

export default {
  name: "WechatAuthorize",
  data () {
    return {
      url: 'https://support.weixin.qq.com/cgi-bin/mmsupport-bin/showredpacket?signature=e6a837f5044bd7db1201bab1ce245ce16c2721acf01cd45709e3953a509f60c2&combinereceiveuri=vPibOoIbcNiHNp9P&check_type=3'
    }
  },
  mounted() {},
  methods: {
    startShard() {
      window.android.shareToWX(1,3,'点击领取，无需分享','点击领取，无需实际分享','https://ppyos.xijiuyou.com/imgs/invite/ppywlogo.png',0,this.url)
    },
    startShardFriend() {
      window.android.shareToWX(0,3,'点击领取，无需分享','点击领取，无需实际分享','https://ppyos.xijiuyou.com/imgs/invite/ppywlogo.png',0,this.url)
    }
  }
}
</script>

<style scoped>
.wxAuth {
  width: 400px;
  height: 160px;
}
</style>
